@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.inter {
  font-family: "Inter", sans-serif;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.Rubik {
  font-family: "Rubik", sans-serif;
}

.font {
  font-family: "Raleway", "Public Sans";
}

.inventory {
  box-shadow: 0px 4px 20px 0px #e3e5f3;
}
input[type="checkbox"] {
  transform: scale(1.2);
  /* margin: 0px 30px 10px; */
}
.tick {
  accent-color: #005555;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scroll-style::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

* {
  margin: 0;
  padding: 0;
}
.scroll-style::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #132d46;
}

.scroll-style::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: gray;
}

.dashboard-scroll-style::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

.dashboard-scroll-style::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #109cf1;
}

.dashboard-scroll-style::-webkit-scrollbar-track {
  border-radius: 6px;
  @apply bg-gray-200;
}

#animate {
  @apply animate-spin;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  display: none;
}
body {
  font-family: "Rubik", sans-serif;
  overflow-x: hidden;
  position: relative;
  @apply bg-gray-50;
}

#bold {
  @apply font-semibold;
}
#not-bold {
  @apply font-normal;
}

/* The container */
.container {
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The container radio */
.contain-radio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  padding-top: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contain-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.circle {
  @apply absolute top-0 left-0 h-6 w-6 rounded-full border-zinc-800 border-2 bg-white;
}
.contain-radio .circle:after {
  left: 4px;
  top: 4px;
  @apply w-3 h-3 rounded-full bg-zinc-800;
}
.contain-radio input:checked ~ .circle:after {
  display: block;
}
.circle:after {
  content: "";
  position: absolute;
  display: none;
}

/* Hide the browser's default checkbox rounded-full w-3 h-3 bg-black*/
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #01c38d;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  position: absolute;
  margin: auto auto;
  inset: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #01c38d;
}
.hide-scroll::-moz-scrollbar {
  display: none;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

.scroll::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(244, 245, 234, 0.3);
}

.gradient {
  background: linear-gradient(
    rgba(255, 255, 255, 0.8927),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
}

#show-cat {
  @apply rounded-b-none;
}

#show-flex {
  display: flex;
}

.let {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeIn {
  animation-name: swipeIn;
  -webkit-animation-name: swipeIn;
}

@keyframes swipeIn {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeIn {
  0% {
    -webkit-transform: translateX(50%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

.swipeInLeft {
  animation-name: swipeInLeft;
  -webkit-animation-name: swipeInLeft;
}

@keyframes swipeInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeInLeft {
  0% {
    -webkit-transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}

.animation {
  animation: typing 2s steps(6);
}

@keyframes typing {
  from {
    width: 0;
  }
}
@-webkit-keyframes typing {
  from {
    width: 0;
  }
}

.enter {
  animation: enter 0.5s linear;
}

@keyframes enter {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 100%;
  }
}
@-webkit-keyframes enter {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 100%;
  }
}

.bouncing {
  animation: bounce 0.4s 8 alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(20);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes glide {
  to {
    top: 40px;
    left: 50px;
  }
}

.lets {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeDown {
  animation-name: swipeUp;
  -webkit-animation-name: swipeUp;
}

@keyframes swipeUp {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes swipeUp {
  0% {
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0px);
  }
}

#show {
  display: block;
}

#hide {
  display: none;
}

#show-cat {
  @apply rounded-b-none;
}

label.label input[type="file"] {
  position: absolute;
  top: -1000px;
}
.label {
  cursor: pointer;
  margin-left: 3px;
  display: inline-block;
}
.right {
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .right {
    width: calc(100% - 250px);
  }
}

.react-tel-input input[type="tel"] {
  width: 100%;
  height: 44px;
  @apply bg-gray-100 border border-gray-200;
}

.react-date-picker {
  @apply bg-white w-[130px] md:w-[200px];
}
.react-date-picker__wrapper {
  @apply rounded-md bg-white px-2 md:px-3 py-1;
}

.react-time-picker {
  @apply bg-white w-[130px] md:w-[200px];
}
.react-time-picker__wrapper {
  @apply rounded-md bg-white px-2 md:px-3 py-1;
}

.filters {
  filter: blur(20px);
}


.let {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeIn {
  animation-name: swipeIn;
  -webkit-animation-name: swipeIn;
}

@keyframes swipeIn {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes swipeIn {
  0% {
    -webkit-transform: translateX(50%);
  }

  100% {
    -webkit-transform: translateX(0px);
  }
}


@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
